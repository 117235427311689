import * as React from "react";
import Layout from "../components/layout";
import Container from "../components/container";
import TopSection from "../components/top-section";
import MidSection from "../components/mid-section";
import UnderlayTitle from "../components/underlay-title";
import PdfMenu from "../components/pdf-menu";
import Button from "../components/button";
import useMenusContent from "../hooks/use-menus-content";

export default function MenuPage() {
  const { drinkMenuPdf, dinnerMenuPdf } = useMenusContent();

  const sections = {
    food: {
      label: "Food",
      slug: "food",
    },
    drink: {
      label: "Drink",
      slug: "drink",
    },
    top: {
      label: "Back",
      slug: "top",
    },
  };

  return (
    <Layout title={"Menu"}>
      <TopSection
        pageTitle={"Menu"}
        sections={[sections.food, sections.drink]}
      />
      <MidSection
        className={"bg-white"}
        slug={sections.food.slug}
        prevSection={sections.top}
        nextSection={sections.drink}
      >
        <Container className={"justify-center items-center"}>
          <div className={"flex justify-center flex-col items-center"}>
            <UnderlayTitle overlay={true} className={"z-0 text-primary"}>
              Food
            </UnderlayTitle>
            <Button
              href={dinnerMenuPdf}
              target={"_blank"}
              theme={"light"}
              hoverTheme={"dark"}
            >
              Download Menu
            </Button>
          </div>
          <div
            className={
              "hidden lg:flex px-4 lg:w-1/2 lg:px-4 justify-center items-center relative flex-col"
            }
          >
            <PdfMenu file={dinnerMenuPdf} />
          </div>
        </Container>
      </MidSection>
      <MidSection
        className={"bg-primary"}
        slug={sections.drink.slug}
        prevSection={sections.food}
      >
        <Container
          className={"justify-center items-center flex-col-reverse lg:flex-row"}
        >
          <div
            className={
              "hidden lg:flex px-4 lg:w-1/2 lg:px-4 justify-center items-center relative flex-col"
            }
          >
            <PdfMenu file={drinkMenuPdf} />
          </div>
          <div className={"flex justify-center flex-col items-center"}>
            <UnderlayTitle overlay={true} className={"z-0 text-white"}>
              Drink
            </UnderlayTitle>
            <Button
              href={drinkMenuPdf}
              target={"_blank"}
              theme={"light"}
              hoverTheme={"dark"}
            >
              Download Menu
            </Button>
          </div>
        </Container>
      </MidSection>
    </Layout>
  );
}
