import * as React from "react";
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack";
import Spinner from "../spinner";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface MenuProps {
  file: any;
  scale?: number;
}

export default function Menu({ file, scale = 1.1 }: MenuProps) {
  return (
    <Document file={file} loading={<Spinner />}>
      <Page scale={scale} pageNumber={1} />
    </Document>
  );
}
